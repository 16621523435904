// Custom Theming for Angular Material
@use '@angular/material' as mat;
@include mat.core();

.shadow-md {
  @include mat.elevation(1);
}

/* Styles to be applied to buttons */
$my-custom-button: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 500,
  $font-size: 12px,
  $line-height: 1,
  $letter-spacing: 'normal'
);

$theme-typography: mat.define-typography-config(
  $font-family: 'Roboto',
  $button: $my-custom-button
);

@include mat.all-component-typographies($theme-typography);


$Content_Lesson_Database-primary: mat.define-palette(mat.$blue-palette, 700, A100, A400);
$Content_Lesson_Database-accent: mat.define-palette(mat.$green-palette, 600);
$Content_Lesson_Database-grey: mat.define-palette(mat.$gray-palette, 400);

$Content_Lesson_Database-edit: mat.define-palette(mat.$red-palette, 800);
// The warn palette is optional (defaults to red).
$Content_Lesson_Database-warn: mat.define-palette(mat.$red-palette);


.bg-primary {
  background-color: mat.get-color-from-palette($Content_Lesson_Database-primary) !important;
}

.primary-color {
  color: mat.get-color-from-palette($Content_Lesson_Database-primary) !important;
}

.accent-color {
  color: mat.get-color-from-palette($Content_Lesson_Database-accent) !important;
}

.accent-grey-color {
  color: mat.get-color-from-palette($Content_Lesson_Database-grey) !important;
}

.tab-active {
  border-bottom: 2px solid black !important;
  background-color: mat.get-color-from-palette($Content_Lesson_Database-grey) !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.nav-button-active {
  border: 2px solid black !important;
  color: black !important;
}

.bg-accent {
  background-color: mat.get-color-from-palette($Content_Lesson_Database-accent) !important;
}

.bg-edit {
  background-color: mat.get-color-from-palette($Content_Lesson_Database-edit) !important;
}

.accent-red-color {
  color: mat.get-color-from-palette($Content_Lesson_Database-edit) !important;
}

$angular-default-theme: mat.define-light-theme(
  (
    color: (
      primary: $Content_Lesson_Database-primary,
      accent: $Content_Lesson_Database-accent,
      warn: $Content_Lesson_Database-warn,
      neutral: $Content_Lesson_Database-grey,
    ), 
      typography: $theme-typography,      
      density: -2
    ));

@include mat.all-component-themes($angular-default-theme);